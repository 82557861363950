<template>
  <PageWrapper>
    <PagePanel>
      <div class="change_password">
        <el-form label-position="top" :model="ruleChangePasswordForm" :rules="rulesChangePassword"
          ref="ruleChangePasswordForm">
          <el-form-item :label="$t('changePw.field.currPw')" prop="currentPass">
            <el-input type="password" v-model="ruleChangePasswordForm.currentPass"
              :placeholder="$t('common.field.pleaseInput')" autocomplete="off" :maxlength="20"></el-input>
          </el-form-item>
          <el-form-item :label="$t('changePw.field.newPw')" prop="pass">
            <el-input type="password" v-model="ruleChangePasswordForm.pass" :placeholder="$t('common.field.pleaseInput')"
              autocomplete="off" :maxlength="20"></el-input>
          </el-form-item>
          <el-form-item :label="$t('changePw.field.confirmPw')" prop="checkPass">
            <el-input type="password" v-model="ruleChangePasswordForm.checkPass"
              :placeholder="$t('common.field.pleaseInput')" autocomplete="off" :maxlength="20"></el-input>
          </el-form-item>
          <el-form-item class="btn-item">
            <el-button type="primary" class="btn-submit" @click="submitForm('ruleChangePasswordForm')"
              data-testid="changePw">
              {{ $t('common.button.submit') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <SDialog :visible="!formFlag" mode="simple" :showFooterCancel="false" :title="$t('common.field.tips')"
        custom-class="change_password_result" @cancel="goHome" @confirm="goHome">
        <StatusMessage :title="$t('promotion.editSuccess')" :content="$t('changePw.succ')">
        </StatusMessage>
      </SDialog>
    </PagePanel>
  </PageWrapper>
</template>

<script>
import { md5 } from '@/util/encrypt';
import { apiCheckPassword, apiUpdatePassword } from '@/resource';
import router from '@/router'
import { validateCommonPassword } from '@/util/validation'

export default {
  name: 'changePassword',
  components: {},
  data() {
    const validateCurrentPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.currPwReq')));
      } else {
        apiCheckPassword({
          currentPass: md5(value)
        }).then(resp => {
          if (!resp.data) {
            callback(new Error(this.$t('common.formValidation.currPwWrong')));
          } else {
            callback();
          }
        });
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else {
        if (!validateCommonPassword(value)) {
          callback(new Error(this.$t('common.formValidation.passwordFormatWrong')));
        } else {
          apiCheckPassword({
            currentPass: md5(value)
          }).then(resp => {
            if (resp.data) {
              callback(new Error(this.$t('common.formValidation.IdenficalPw')));
            } else {
              callback();
            }
          });
        }
      }
    };
    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else if (value !== this.ruleChangePasswordForm.pass) {
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      }
      callback();
    };
    return {
      formFlag: true,
      ruleChangePasswordForm: {
        currentPass: '',
        pass: '',
        checkPass: ''
      },
      rulesChangePassword: {
        currentPass: [{ required: true, validator: validateCurrentPass, trigger: ['blur'] }],
        pass: [{ required: true, validator: validatePass, trigger: ['blur', 'change'] }],
        checkPass: [{ required: true, validator: validateCheckPass, trigger: ['blur', 'change'] }]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) this.updatePassword();
        else return false;
      });
    },
    updatePassword() {
      apiUpdatePassword({
        currentPass: md5(this.ruleChangePasswordForm.currentPass),
        pass: md5(this.ruleChangePasswordForm.pass),
        checkPass: md5(this.ruleChangePasswordForm.checkPass)
      }).then(resp => {
        if (resp.data.code === 0) this.formFlag = false;
      });
    },
    goHome() {
      router.push('/home')
    }
  }
};
</script>

<style lang="scss" scoped>
.change_password {
  max-width: 392px;
  margin: 0 auto;

  .btn-item {
    text-align: center;
  }
}
</style>
